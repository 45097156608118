import styled from 'styled-components'

export const PositionStyles = styled.div`
	#div_position {
		display: flex;
		text-align: center;
		justify-content: center;
		margin: 10px auto -68px auto;

		#position {
			font-size: 24px;
			min-width: 55px;
			min-height: 55px;
			border-radius: 50%;
			width: fit-content;
			height: fit-content;
			background-color: transparent;

			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
		}
	}

	svg {
		width: 5.75em;
		transform-origin: center;
		animation: rotate 3s linear infinite;
	}

	circle {
		fill: none;
		stroke: #32c873;
		stroke-width: 2;
		stroke-dashoffset: 0;
		stroke-linecap: round;
		stroke-dasharray: 1, 200;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 200;
			stroke-dashoffset: -35px;
		}
		100% {
			stroke-dashoffset: -125px;
		}
	}
`
