import { VenueInfo } from '../store/interfaces'

export function handleSessionStorage(item: string) {
	// It gets the venueInfo stored in the sessionStorage
	let sessionVenueInfo = sessionStorage.getItem(item)
	// If the there's information available in the sessionStorage it'll parse to JSON, otherwise it will be set as null
	const parsedSessionStorage: VenueInfo = sessionVenueInfo && JSON.parse(sessionVenueInfo)
	// It checks if sessionStorage is properly filled
	const sessionStorageFilled = !!parsedSessionStorage
	// If the sessionStorage is filled it will get the id stored
	const sessionStorageId = parsedSessionStorage?._id

	return { sessionStorageFilled, sessionStorageId, parsedSessionStorage }
}
