import styled from 'styled-components'

export const FooterStyles = styled('footer')<{
	colorBackground: string
	colorBorder: string
}>`
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding: 0 10px 10px 10px;
	box-sizing: border-box;
	background-color: ${props => props.colorBackground};

	div {
		border-top: 1px solid ${props => props.colorBorder};
	}

	@media (min-width: 1024px) {
		position: inherit;
		bottom: 0px;
		left: 0px;
		width: 100%;
		padding: 0;
		box-sizing: border-box;
		background-color: ${props => props.colorBackground};
	}
`
