// Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducer'

// Languages
import i18n from 'i18next'

// Components
import LanguageSelector from '../LanguageSelector'

// Styles
import { HeaderStyles } from './styles'

// Interfaces
import { COLORS } from '../../common/constants'
import { Language } from '../../common/interfaces'

function WaitlistHeader() {
	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	// Extract the current language from the i18next
	const language = i18n.language as keyof Language

	return (
		<HeaderStyles
			smallBackgroundImage={venueInfo.smallBackgroundImage}
			largeBackgroundImage={venueInfo.largeBackgroundImage}
			colorBackground={venueInfo.color.background || COLORS.background}
			colorMain={venueInfo.color.main || COLORS.main}
		>
			<div id="div_header_top">
				<div id="div_header_left">
					<div id="div_logo">
						{venueInfo.logoHorizontal ? (
							<img
								src={venueInfo.logoHorizontal}
								alt={`logo_${venueInfo.venueName[language] || venueInfo.venueName.pt}`}
							/>
						) : (
							<></>
						)}
					</div>

					<div id="div_venue_info">
						<p id="venue_name">{venueInfo.venueName[language] || venueInfo.venueName.pt}</p>

						{/* <p id="venue_location">
							{venueInfo.neighborhood} • {venueInfo.city}
						</p> */}
					</div>
				</div>

				<div id="div_internacionalization">
					<LanguageSelector minForm={true} />
				</div>
			</div>

			<div id="div_background"></div>
		</HeaderStyles>
	)
}

export default WaitlistHeader
