import { ClientNotices } from '../../common/interfaces'

export interface WaitListResponseParams {
	waitlistId: string
}

export enum OperationModeType {
	ADVANCED = 'advanced',
	BASIC = 'basic',
}

export enum WaitlistStatusType {
	ACTIVE = 'active',
	NOTIFIED = 'notified',
	SEATED = 'seated',
	CANCELED = 'canceled',
}

export interface WaitListInfo {
	status: WaitlistStatusType
	priority: boolean | null
	_id: string
	customer: {
		_id: string
		name: string
	}
	customerTab: string
	partySize: number
	venue: string
	showClientPosition: boolean
	operationMode: OperationModeType
	clientNotices?: ClientNotices
}
