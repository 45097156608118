import styled from 'styled-components'
import { COLORS } from '../../common/constants'

export const Container = styled('div')<{
	colorMain: string
	colorBorder: string
}>`
	display: flex;
	color: #464646;
	border-radius: 3px;
	margin-bottom: 11px;
	flex-direction: column;
	border: 1px solid ${p => p.colorBorder};

	.intro {
		display: flex;
		padding: 10px;
		color: #464646;
		align-items: center;

		.icon {
			display: flex;
			font-size: 28px;
			min-width: 34px;
			padding-right: 10px;
			align-items: center;
			justify-content: center;
		}

		.desc {
			flex: 1;
			line-height: 19px;

			h2 {
				font-size: 14px;
				margin: 0 0 2px;
				font-weight: 500;
				line-height: 1.2;
			}

			p {
				margin: 0;
				font-size: 12px;
				font-weight: 300;
				line-height: 16px;
			}
		}

		.action {
			button {
				height: 32px;
				padding: 2px;
				min-width: 78px;
				font-size: 12px;
				cursor: pointer;
				margin-left: auto;
				border-radius: 3px;
				text-align: center;
				text-decoration: none;
				display: inline-block;
				background-color: transparent;
				/* color: ${props => props.colorMain}; */
				color: ${COLORS.fontColor};
				border: 2px solid ${props => props.colorBorder};
			}
		}
	}
`
