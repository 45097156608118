import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  * {
    font-family: "Open Sans", sans-serif;
  }

  body {
    margin: 0;
    padding: 0rem;
    background-color: ${props => props.colorBackground};
    /*font: normal normal normal 14px/1 Tagme;*/
    ${props => (props.modalOpened ? 'overflow: hidden;' : '')}
  }

  html, #root {
    background-color: ${props => props.colorBackground};
  }
`

interface GlobalStyleProps {
	modalOpened?: boolean
	colorBackground?: string
}
