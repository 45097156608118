import styled from 'styled-components'

export const TagmeButtonBackStyles = styled.div`
	button {
		border: 0;
		padding: 0;
		width: 100%;
		display: flex;
		color: #30323b;
		margin-top: 10px;
		line-height: 46px;
		padding-left: 10px;
		border-radius: 4px;
		align-items: center;
		border: 1px solid #e4dfd0;
		background-color: transparent;
		justify-content: space-between;

		#label_back_button {
			font-size: 14px;
			font-weight: 600;
		}

		.icon {
			font-size: 24px;
			font-weight: 600;
		}
	}

	.disabled {
		opacity: 60%;
	}

	.aligner {
		padding-left: 30px;
	}
`
