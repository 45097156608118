import styled from 'styled-components'

export const ContainerStyles = styled('div')<{
	colorBackground: string
	colorMain: string
	colorBorder: string
}>`
	padding: 0 10px;
	background-color: ${props => props.colorBackground};

	#div_button_leave_waitlist {
		display: flex;
		cursor: pointer;
		margin-bottom: 20px;
		justify-content: center;

		.outline_tagme_button {
			width: 50%;
			font-size: 14px;
			max-width: 200px;
			line-height: 34px;
			border-radius: 3px;
			box-sizing: border-box;
			background: transparent;
			border: 2px solid ${props => props.colorMain};
			color: ${props => props.colorMain};
		}
	}

	.div_attention {
		margin: 8px auto;
		border-radius: 3px;
		background: #fff3f6;
		box-sizing: border-box;
		border: 1px solid #fedee5;

		p {
			color: #de1d48;
			font-size: 12px;
			padding: 0 10px;
			line-height: 18px;
		}

		@media (min-width: 620px) {
			text-align: center;
		}
	}

	@media (min-width: 620px) {
		text-align: center;
	}

	#div_priority {
		margin: 8px auto;
		border-radius: 3px;
		background: #fffde5;
		box-sizing: border-box;
		border: 1px solid #ffe5a4;

		p {
			color: #d09636;
			font-size: 12px;
			padding: 0 10px;
			line-height: 18px;
		}

		@media (min-width: 620px) {
			text-align: center;
		}
	}

	.div_call,
	.div_seat {
		margin: -120px 10px;
		h1 {
			margin: 0px 10px;
			font-size: 34px;
			line-height: 42px;
			color: ${props => props.colorMain};
			text-align: center;
			font-weight: 600;
		}

		p {
			margin: 20px 10px;
			font-size: 14px;
			line-height: 18px;
			color: #30323b;
			text-align: center;
			font-weight: 300;
		}
	}

	#div_hide_customer_position {
		margin: 20px 10px;
		text-align: center;
		font-size: 14px;
		line-height: 18px;
		color: #30323b;
		font-weight: 300;
	}
`

export const ClientPositionStyles = styled('div')<{
	colorMain: string
	colorBackground: string
}>`
	font-size: 14px;
	margin-top: 30px;
	font-weight: 300;
	line-height: 18px;
	text-align: center;

	strong {
		color: ${props => props.colorMain};
	}

	small {
		font-size: 12px;
		line-height: 14px;
	}

	.highlighted {
		font-weight: bold;
	}
`

export const FooterStyles = styled('footer')<{
	colorBackground: string
	colorBorder: string
}>`
	left: 0px;
	bottom: 0px;
	width: 100%;
	position: fixed;
	box-sizing: border-box;
	padding: 0 10px 10px 10px;
	background-color: ${props => props.colorBackground};

	p {
		font-size: 14px;
		text-align: center;
		margin-bottom: 0px;
	}

	.divider {
		border-top: 1px solid ${props => props.colorBorder};
	}
`
