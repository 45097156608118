import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

// Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducer'

// Axios instances
import { customerApi } from '../../api/axios.config'

// Languages
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

// Styles
import { FooterStyles } from './styles'

// Components
import DisclamerServiceTerms from '../DisclamerServiceTerms'
import TagmeButton from '../TagmeButton'
import TagmeInput from '../TagmeInput'
import TagmeModal from '../TagmeModal'
import TagmeSelect from '../TagmeSelect'

// Interfaces
import { Language } from '../../common/interfaces'
import { ModalProps, ModalTypes } from '../TagmeModal/interface'

// Errors
import * as Sentry from '@sentry/react'
import { COLORS } from '../../common/constants'
import { ErrorsList } from '../../common/errors'
import { showNotification } from '../../Services/notification.service'

// Utils
import { getNameAndLastName } from '../../utils/stringsUtils'

declare module 'i18next' {
	interface CustomTypeOptions {
		returnNull: false;
	}
}
//TextsDinamics

function RemoteWaitlistForm({ fetchStatusData }: any) {
	const history = useNavigate()

	const [modalInfo, setModalInfo] = useState<ModalProps>({
		show: false,
		type: ModalTypes.error,
	})

	// Extract the current language from the i18next
	const language = i18n.language as keyof Language

	// Handle the text translation
	const { t } = useTranslation()

	// Global state
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)
	const currentLanguage = useSelector((state: RootState) => state.entities.language)

	// Form States
	const [partySize, setPartySize] = useState('')
	const [name, setName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState<string>()
	const [observation, setObservation] = useState('')

	// Name validation state
	const [nameValidation, setNameValidation] = useState({
		nameHasError: false,
		nameIsValid: false,
	})

	// Phone validation state
	const [phoneNumberValidation, setPhoneNumberValidation] = useState({
		phoneHasError: false,
		phoneIsValid: false,
	})

	// Email validation state
	const [emailValidation, setEmailValidation] = useState({
		emailHasError: false,
		emailIsValid: false,
	})

	// Select validation state
	const [selectValidation, setSelectValidation] = useState({
		selectHasError: false,
		selectIsValid: false,
	})

	// Function that given the maxPartySize fetched from the API, generates the partySizeOptions html automatically
	const generatePartySizeOptions = () => {
		const partySizeOptions = []

		for (let i = 1; i <= venueInfo.maxPartySize; i++) {
			const obj = {
				label: `${i} ${t('seats')}`,
				value: i,
			}

			if (i === 1) {
				obj.label = `${i} ${t('seat')}`
			}

			partySizeOptions.push(obj)
		}

		return partySizeOptions
	}

	const closeModal = useCallback(() => {
		setModalInfo(previousModalInfoState => ({ ...previousModalInfoState, show: false }))
	}, [])

	// By making 'buttonDisabled' not a const, I can change it to false after clicking it, preventing multiples requests.
	let [buttonDisabled, toggleButtonStatus] = useState(true)

	// Validates partySize automatically
	useEffect(() => {
		if (partySize) {
			setSelectValidation({ selectHasError: false, selectIsValid: true })
		}
	}, [partySize])

	// Validates name automatically
	useEffect(() => {
		const checkName = name.match(/^([A-zÁ-ú]){2,}\s([A-zÁ-ú.]\s?){2,}/)

		if (name) {
			if (!checkName) {
				setNameValidation({
					nameHasError: true,
					nameIsValid: false,
				})
			} else {
				setNameValidation({
					nameHasError: false,
					nameIsValid: true,
				})
			}
		}
	}, [name])

	// Validates phoneNumber automatically
	useEffect(() => {
		const checkPhoneNumber = phoneNumber.match(/^\(\d{2}\)\s\d{4,5}-\d{4}$/)

		if (phoneNumber) {
			if (!checkPhoneNumber) {
				setPhoneNumberValidation({
					phoneHasError: true,
					phoneIsValid: false,
				})
			} else {
				setPhoneNumberValidation({
					phoneHasError: false,
					phoneIsValid: true,
				})
			}
		}
	}, [phoneNumber])

	// Validates email automatically
	useEffect(() => {
		const checkEmail = email && email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)

		if (email) {
			if (checkEmail && checkEmail[0] === email) {
				setEmailValidation({
					emailHasError: false,
					emailIsValid: true,
				})
			} else {
				setEmailValidation({
					emailHasError: true,
					emailIsValid: false,
				})
			}
		} else {
			setEmailValidation({
				emailHasError: false,
				emailIsValid: false,
			})
		}
	}, [email])

	// If all the fields are properlly filled, it will toggle the state of the submit button to disabled=false, otherwise it will toggle to disabled=true
	useEffect(() => {
		if (
			!emailValidation.emailHasError &&
			nameValidation.nameIsValid &&
			phoneNumberValidation.phoneIsValid &&
			selectValidation.selectIsValid
		) {
			toggleButtonStatus(false)
		} else {
			toggleButtonStatus(true)
		}
	}, [emailValidation, nameValidation, phoneNumberValidation, selectValidation])

	// Form Submit
	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		// show modal if not active
		const isActive = await fetchStatusData()
		if (!isActive) return

		// If the button status is disabled=true it means that some of the fields is not properly filled,
		// so it won't post the form and will show which field is incorrect.
		if (buttonDisabled) {
			// It will show that the field name is required and has error
			if (!nameValidation.nameHasError && !nameValidation.nameIsValid) {
				setNameValidation({ ...nameValidation, nameHasError: true })
			}

			// It will show that the field phoneNumber is required and has error
			if (!phoneNumberValidation.phoneHasError && !phoneNumberValidation.phoneIsValid) {
				setPhoneNumberValidation({
					...phoneNumberValidation,
					phoneHasError: true,
				})
			}

			// It will show that the field select is required and has error
			if (!selectValidation.selectHasError && !selectValidation.selectIsValid) {
				setSelectValidation({ ...selectValidation, selectHasError: true })
			}
		}
		// If the button status is disabled=false, it means that everything is good and that we can proceed with the post
		else {
			//it is a precaution to not allow multiples clicks on the button
			buttonDisabled = true

			try {
				// Prepare the data to be posted
				const [firstName, lastName] = getNameAndLastName(name)

				const data = {
					customer: {
						name: firstName,
						lastName,
						phone: phoneNumber,
						// do not send empty string as email
						email: email ? email : undefined,
					},
					venue: venueInfo._id,
					origin: {
						label: 'Widget',
					},
					partySize: partySize,
					note: observation,
				}

				// Effectivelly post the data and insert the user in the waitlist
				const response = await customerApi.post(`/Waitlist/${venueInfo._id}`, data)

				// Extracts the waitlistId from the response data
				const waitlistId = response.data._id

				//Show the message when the client entered the waitlist.
				const messagePushActive = venueInfo.messagesPush.welcome
				if (venueInfo.messagesPush.enableMessage && messagePushActive?.enabled) {
					//prepares the information to be shown to the client
					const welcomeNotification = {
						title: venueInfo.venueName[currentLanguage.code],
						body: messagePushActive.message[currentLanguage.code],
					}
					showNotification(welcomeNotification)
				}

				waitlistId &&
					setModalInfo({
						show: true,
						closable: false,
						type: ModalTypes.success,
						title: "You've joined the waitlist",
						message: [
							"We've sent a SMS with a link for you to follow your position in the waitlist.",
							'Look for the receptionist when you arrive.',
						],
						buttons: [
							{
								label: 'Follow',
								action: () => {
									history(`/waitlist/${waitlistId}`)
								},
							},
						],
					})
			} catch (error) {
				setModalInfo({
					show: true,
					onClose: closeModal,
					type: ModalTypes.error,
					message: 'Failed to create waitlist.',
					buttons: [
						{
							label: 'OK',
							action: closeModal,
						},
					],
				})

				Sentry.setTag('venueId', venueInfo._id)
				Sentry.captureException(new Error(ErrorsList.FAILED_TO_CREATE_NEW_WAITLIST))
			}
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
				<TagmeSelect
					name="partySize"
					icon="tagmeicon-fila-2"
					label={venueInfo.genericVenue ? t('Number of people?') : t('Table for how many people?')}
					placeholder={t('Select')}
					options={generatePartySizeOptions()}
					border={true}
					featuredEnabled={true}
					valid={selectValidation.selectIsValid}
					hasError={selectValidation.selectHasError}
					state={partySize}
					setState={setPartySize}
				/>

				{partySize ? (
					<>
						<div className="personal_data">{t('Personal Data')}</div>

						<TagmeInput
							state={phoneNumber}
							setState={setPhoneNumber}
							border={true}
							icon="tagmeicon-telefone"
							label={t('Phone Number')}
							placeholder="Ex.: (21) 99315-7373"
							name="phoneNumber"
							type="text"
							mask="(99) 99999-9999"
							hint={t('Required')}
							featuredEnabled={true}
							hasError={phoneNumberValidation.phoneHasError}
							valid={phoneNumberValidation.phoneIsValid}
							errorMsg={t('Invalid phone number')}
						/>

						<TagmeInput
							state={name}
							setState={setName}
							border={true}
							icon="tagmeicon-nome"
							label={t('Name and Surname')}
							placeholder="Ex.: João Silva"
							name="name"
							type="text"
							hint={t('Required')}
							featuredEnabled={true}
							hasError={nameValidation.nameHasError}
							valid={nameValidation.nameIsValid}
							errorMsg={t('Insert Surname')}
						/>

						<TagmeInput
							state={email}
							setState={setEmail}
							border={true}
							icon="tagmeicon-email"
							label={t('Email')}
							placeholder="Ex.: joao@gmail.com"
							name="email"
							type="text"
							featuredEnabled={true}
							hasError={emailValidation.emailHasError}
							valid={emailValidation.emailIsValid && !emailValidation.emailHasError}
							errorMsg={t('Invalid email')}
						/>

						<TagmeInput
							state={observation}
							setState={setObservation}
							border={true}
							icon="tagmeicon-editar"
							label={t('Remarks and requests')}
							placeholder={t('There is no guarantee that they will be served.')}
							name="observation"
							type="text"
							featuredEnabled={false}
							maxLength={255}
						/>
					</>
				) : (
					<></>
				)}
				<DisclamerServiceTerms colorMain={venueInfo.color.main} venueName={venueInfo.venueName[language]} />

				<FooterStyles
					colorBorder={venueInfo.color.border || COLORS.border}
					colorBackground={venueInfo.color.background || COLORS.background}
				>
					<TagmeButton
						type="button"
						label={t('Get in waitlist')}
						colorMain={venueInfo.color.main}
						disabled={buttonDisabled}
						handleClick={handleSubmit}
					/>
				</FooterStyles>
			</form>

			<TagmeModal {...modalInfo} />
		</>
	)
}

export default RemoteWaitlistForm
