// React
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'

// Redux
import { RootState } from '../../store/reducer'
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { fetchVenueInfoData, venueInfoUpdated } from '../../store/venueInfo'

// Axios instances
import { customerApi } from '../../api/axios.config'

// Styles
import { ContainerStyles } from './styles'
import { GlobalStyle } from '../../styles/global'

// Components
import TagmeModal from '../../Components/TagmeModal'
import WaitlistHeader from '../../Components/WaitlistHeader'
import ServiceSelector from '../../Components/ServiceSelector'
import RemoteWaitlistForm from '../../Components/RemoteWaitlistForm'

// Errors
import { ErrorsList } from '../../common/errors'
import * as Sentry from '@sentry/react'

// Interfaces
import { ModalProps, ModalTypes } from '../../Components/TagmeModal/interface'
import { SuccessUpdateVenueInfo } from '../../store/interfaces'

// Utils
import { handleSessionStorage } from '../../utils/sessionStorageManagement'
import { COLORS } from '../../common/constants'

import { useTranslation } from 'react-i18next'
import { QueueStatusType } from './enums/queue-status.enum'


function RemoteWaitList() {
	const history = useNavigate()

	const [modalInfo, setModalInfo] = useState<ModalProps>({
		show: false,
		type: ModalTypes.error,
	})

	// Extract the route param venueId from the url
	const { venueId } = useParams()

	const dispatch = useDispatch()

	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)
	const currentLanguage = useSelector((state: RootState) => state.entities.language)

	// Function for redirecting the client to the smartLink page
	const handleToSmartlink = useCallback(() => {
		window.location.href = `${process.env.REACT_APP_RESERVATION_WIDGET_URL}/smartlink/${venueId}`
	}, [venueId])

	const closeModal = useCallback(() => {
		setModalInfo(previousModalInfoState => ({ ...previousModalInfoState, show: false }))
	}, [])

	// Handle the text translation
	const { t } = useTranslation()

	const fetchStatusData = useCallback(async (): Promise<boolean> => {
		try {
			const venueStatusResponse = await customerApi.get(`/status/${venueId}/widget`)

			const { active, description } = venueStatusResponse.data

			if (active) return true

			setModalInfo({
				show: true,
				closable: false,
				type: ModalTypes.noIcon,
				message: description === QueueStatusType.EMPTY ? `${t('There is no waitlist at the moment.')}` : `${t('Remote waitlist closed.')}`,
				buttons: [
					{
						label: 'OK',
						action: handleToSmartlink,
					},
				],
			})

			return true
		} catch (error) {
			setModalInfo({
				show: true,
				onClose: closeModal,
				type: ModalTypes.error,
				message: 'Venue not found.',
				buttons: [
					{
						label: 'OK',
						action: () => history(`/404-not-found`),
					},
				],
			})

			Sentry.setTag('venueId', venueId)
			Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_WAITLIST_STATUS))

			return false
		}
	}, [closeModal, handleToSmartlink, history, t, venueId])

	const fetchGeneralInformationWidget = () => {
		if (venueInfo?.generalInformationWidget.enabled) {
			const customText = venueInfo?.generalInformationWidget.message[currentLanguage.code]
			return (
				<div id="div_priority">
					<p>{customText}</p>
				</div>
			)
		}
	}

	// It checks the venue availability
	useEffect(() => {
		fetchStatusData()
	}, [fetchStatusData])

	// Update the venueInfo
	useEffect(() => {
		async function fetchVenueData() {
			try {
				// Fetch the information available in the sessionStorage
				const { sessionStorageFilled, sessionStorageId, parsedSessionStorage } =
					handleSessionStorage('venueInfo')

				// The conditionals bellow only will be checked if the venueInfo (global state) is not filled
				// If the sessionStorage is properly filled, the venueInfo will be set based on this information,
				// avoiding unncessary resquests
				if (sessionStorageFilled && sessionStorageId === venueId) {
					return dispatch(venueInfoUpdated(parsedSessionStorage))
				}

				// Thunk Action for fetching data in the API and updating the global state venueInfo
				const successUpdateVenueInfo = (await dispatch(fetchVenueInfoData(venueId))) as SuccessUpdateVenueInfo

				if (successUpdateVenueInfo.errorsMessages.length > 0) {
					let hasOnlyThemeError = false

					for (const errorMessage of successUpdateVenueInfo.errorsMessages) {
						hasOnlyThemeError =
							successUpdateVenueInfo.errorsMessages.length === 1 && errorMessage.includes('THEME')
						Sentry.configureScope(function (scope) {
							scope.setLevel("warning");
						});
						Sentry.setTag('venueId', venueId)
						Sentry.captureMessage(errorMessage)
					}

					if (!hasOnlyThemeError) {
						setModalInfo({
							show: true,
							closable: false,
							type: ModalTypes.noIcon,
							message: venueInfo.genericVenue
								? `${t('There is no queue at this time. Go directly to the establishment.')}`
								: `${t('Waitlist is currently inaccessible. ')}`,
							buttons: [
								{
									label: 'OK',
									action: handleToSmartlink,
								},
							],
						})
					}
				}
			} catch (error) {
				Sentry.setTag('venueId', venueId)
				Sentry.captureException(new Error(ErrorsList.UNKNOWN_ERROR))
			}
		}

		// If the venueInfo is filled, we don't need to proceed, so the fetchVenueData function bellow won't be called
		if (!venueInfo._id) {
			fetchVenueData()
		}
	}, [closeModal, dispatch, handleToSmartlink, history, t, venueId, venueInfo._id, venueInfo.genericVenue])

	const handlePriorityDisclaimer = () => {
		const priorityText = venueInfo.genericVenue
			? `${t('In case of priority, look for one of our attendants when you arrive at the establishment.')}`
			: `${t('In case of priority, look for one of our attendants when you arrive at the restaurant.')}`
		return (
			<div id="div_priority">
				<p>{priorityText}</p>
			</div>
		)
	}

	return (
		<ContainerStyles
			colorBackground={venueInfo.color.background || COLORS.background}
			colorBorder={venueInfo.color.border || COLORS.border}
		>
			<WaitlistHeader />

			{fetchGeneralInformationWidget()}

			<ServiceSelector />

			{handlePriorityDisclaimer()}

			<RemoteWaitlistForm fetchStatusData={fetchStatusData} />

			<TagmeModal {...modalInfo} />

			<GlobalStyle
				colorBackground={venueInfo.color.background || COLORS.background}
				modalOpened={modalInfo.show}
			/>
		</ContainerStyles>
	)
}

export default RemoteWaitList
