import { combineReducers } from '@reduxjs/toolkit'

// Reducers
import venueInfoReducer from './venueInfo'
import languageReducer from './language'

// It will be usefull if we want to add new entities reducers in the future, so far we only have the venueInfo entity
const entitiesReducer = combineReducers({
	venueInfo: venueInfoReducer,
	language: languageReducer,
})

export default entitiesReducer
