import { Route, BrowserRouter, Routes } from 'react-router-dom'

// Redux
import { Provider } from 'react-redux'
import store from './store/configureStore'

// Components
import WaitList from './Pages/WaitList'
import NotFoundPage from './Pages/NotFoundPage'
import RemoteWaitList from './Pages/RemoteWaitList'
import TermsOfService from './Pages/TermsOfService'
import RemoveClient from './Pages/RemoveClient'

//Sentry
import * as Sentry from '@sentry/react'

function App() {
	return (
		<BrowserRouter>
			<Provider store={store}>
				<Routes>
					<Route path="/waitlist/:waitlistId" element={<WaitList/>} />
					<Route path="/waitlist/:waitlistId/cancel" element={<RemoveClient/>} />
					<Route path="/remote-waitlist/:venueId" element={<RemoteWaitList/>} />
					<Route path="/terms-service" element={<TermsOfService/>} />
					<Route path="*" element={<NotFoundPage/>} />
				</Routes>
			</Provider>
		</BrowserRouter>
	)
}

//export default App
export default Sentry.withProfiler(App)
