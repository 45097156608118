import { NotFoundPageStyles } from './styles'
import image404NotFound from '../../assets/images/404.jpg'

function NotFoundPage() {
	return (
		<NotFoundPageStyles>
			<img src={image404NotFound} alt="404 Not Found" />
		</NotFoundPageStyles>
	)
}

export default NotFoundPage
