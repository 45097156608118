import { OperationModeType, WaitlistStatusType, WaitListInfo } from '../interfaces'

export const waitlistInitialState: WaitListInfo = {
	status: WaitlistStatusType.ACTIVE,
	priority: null,
	_id: '',
	customer: {
		_id: '',
		name: '',
	},
	customerTab: '',
	partySize: 1,
	venue: '',
	showClientPosition: true,
	operationMode: OperationModeType.ADVANCED,
}
