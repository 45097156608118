import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

//Sentry
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App'
import pt from './common/translation/pt.json'
import en from './common/translation/en.json'

// Config the i18n for text translation
const resources = {
	en: {
		translation: en,
	},
	pt: {
		translation: pt,
	},
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.init({
		supportedLngs: ['pt', 'en'],
		fallbackLng: 'pt',
		detection: {
			order: ['localStorage', 'cookie', 'htmlTag', 'navigator', 'path', 'subdomain'],
			caches: ['localStorage'],
		},
		interpolation: {
			escapeValue: false,
		},
		react: { useSuspense: false },
		resources,
	})

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DNS,
	integrations: [new Integrations.BrowserTracing()],

	// We recommend adjusting this value in production, or using tracesSampler for finer control
	tracesSampleRate: 1.0,
	environment: process.env.NODE_ENV,
})

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,

	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
