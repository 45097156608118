import styled from 'styled-components'

export const HeaderStyles = styled('header')<{
	colorBackground: string
	smallBackgroundImage: string
	largeBackgroundImage: string
	colorMain: string
}>`
	#div_header_top {
		display: flex;
		margin: 5px auto;
		align-items: center;
		align-content: center;
		background-color: ${props => props.colorBackground};
		justify-content: space-between;
		color: ${props => props.colorMain};
		min-height: 50px;

		#div_header_left {
			display: flex;
			align-items: center;

			img {
				width: 80px;
				margin-top: 6px;
			}

			#div_venue_info {
				margin-left: 10px;

				#venue_name {
					margin: 0;
					font-size: 16px;
					font-weight: 600;
				}

				#venue_location {
					margin: 3px 0 10px 0;
					font-size: 12px;
					font-weight: 300;
				}
			}
		}

		#div_internacionalization {
			height: 30px;
			display: flex;
			align-items: center;
		}
	}

	#div_background {
		height: 200px;
		padding: 8px 10px 0;
		background-size: cover;
		margin: 0px -10px 10px;
		background-position: 50%;
		background-image: url(${props => props.smallBackgroundImage});

		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (min-width: 600px) {
			background-image: url(${props => props.largeBackgroundImage});
		}
	}
`
