// React
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// Redux
import { RootState } from '../../store/reducer'
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { fetchVenueInfoData, venueInfoUpdated } from '../../store/venueInfo'

// Languages
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

// Axios instances
import { customerApi } from '../../api/axios.config'

// Components
import TagmeModal from '../../Components/TagmeModal'
import TagmeButton from '../../Components/TagmeButton'
import WaitlistHeader from '../../Components/WaitlistHeader'
import PositionIndicator from '../../Components/PositionIndicator'
import DisclamerServiceTerms from '../../Components/DisclamerServiceTerms'

// Styles
import { GlobalStyle } from '../../styles/global'
import { ContainerStyles, FooterStyles, ClientPositionStyles } from './styles'

// Interfaces
import { SuccessUpdateVenueInfo } from '../../store/interfaces'
import { WaitListInfo, OperationModeType, WaitlistStatusType } from './interfaces'
import { ModalProps, ModalTypes, ButtonTypes } from '../../Components/TagmeModal/interface'

// Utils
import { handleSessionStorage } from '../../utils/sessionStorageManagement'

// Service
import { connectWebsocket } from '../../Services/websocket.service'

// Errors
import * as Sentry from '@sentry/react'
import { ErrorsList } from '../../common/errors'

// Constants
import { WAITLIST_EVENT_TYPE } from './constants/websocket-events.constants'
import { waitlistInitialState } from './constants/initalState.constant'

// import '@lottiefiles/lottie-player'
import { Player } from '@lottiefiles/react-lottie-player'
import { COLORS } from '../../common/constants'
import { Language } from '../../common/interfaces'
import { showNotification } from '../../Services/notification.service'

function WaitList() {
	// States

	const [waitlistState, setWaitlistState] = useState<WaitListInfo>(waitlistInitialState)

	const [position, setPosition] = useState<number>(0)

	const [globalPosition, setGlobalPosition] = useState<number>(0)

	const [waitlistStatus, setWaitlistStatus] = useState<WaitlistStatusType>(WaitlistStatusType.ACTIVE)

	const [modalInfo, setModalInfo] = useState<ModalProps>({
		show: false,
		type: ModalTypes.error,
	})

	// Handle the text translation
	const { t } = useTranslation()

	// Extract the current language from the i18next
	const language = i18n.language as keyof Language

	const dispatch = useDispatch()

	// Get waitlistId by url parameter
	const { waitlistId } = useParams()

	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)
	const currentLanguage = useSelector((state: RootState) => state.entities.language)

	// Position to show alert "Stay Close"
	const positionAlertStayClose = 3

	// --------------------------- HANDLES
	const handleToSmartlink = useCallback(() => {
		window.location.href = `${process.env.REACT_APP_RESERVATION_WIDGET_URL}/smartlink/${venueInfo._id}`
	}, [venueInfo._id])

	// Modal handles
	const handleCloseModal = useCallback(() => {
		setModalInfo(previousModalInfoState => ({ ...previousModalInfoState, show: false }))
	}, [])

	const handleTagmeModal = (props: ModalProps) => {
		return (
			<TagmeModal
				show={props.show}
				type={props.type}
				onClose={props.onClose}
				closable={props.closable}
				closeOutsideClick={props.closeOutsideClick}
				title={props.title}
				message={props.message}
				buttons={props.buttons}
			/>
		)
	}

	const handleShowModalLeaveWaitlist = () => {
		setModalInfo({
			show: true,
			onClose: handleCloseModal,
			type: ModalTypes.noIcon,
			message: 'Would you like to leave the waitlist?',
			buttons: [
				{
					label: 'Yes',
					action: () => handleLeaveWaitlist(),
					type: ButtonTypes.primary,
					id: 'confirm_leave_waitlist_button',
				},
			],
		})
	}

	const handleGetWaitlistData = useCallback(async () => {
		try {
			const responseWithPosition = await customerApi.get(`/Waitlist/${waitlistId}/widget`)
			const responseWithPositionData = responseWithPosition.data

			const { waitlist, waitlistSettings } = responseWithPositionData

			const { pos, globalPos, status, ...rest } = waitlist

			const {
				operational: { showClientPosition },
				operationMode,
				clientNotices,
			} = waitlistSettings

			setPosition(pos)
			setGlobalPosition(globalPos)

			setWaitlistStatus(status)

			setWaitlistState({
				...rest,
				showClientPosition,
				operationMode,
				clientNotices,
			})
		} catch (error) {
			setModalInfo({
				show: true,
				onClose: handleCloseModal,
				type: ModalTypes.error,
				message: 'Failed to get waitlist information.',
				buttons: [
					{
						label: 'OK',
						action: handleCloseModal,
					},
				],
			})

			Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_WAITLIST_INFORMATION_WITH_POSITION))
		}
	}, [handleCloseModal, waitlistId])

	const handleLeaveWaitlist = useCallback(async () => {
		try {
			const data = {
				customer: waitlistState.customer?._id,
				cancelReason: 'Customer',
			}

			await customerApi.put(`/Waitlist/${waitlistId}/Cancel`, data)

			setWaitlistState(waitlistInitialState)
			handleCloseModal()
		} catch (error) {
			setModalInfo({
				show: true,
				onClose: handleCloseModal,
				type: ModalTypes.error,
				message: 'Failed to leave the waitlist.',
				buttons: [
					{
						label: 'OK',
						action: handleCloseModal,
					},
				],
			})

			Sentry.setTag('venueId', waitlistState.venue)
			Sentry.captureException(new Error(ErrorsList.FAILED_TO_LEAVE_WAITLIST))
		}
	}, [handleCloseModal, waitlistId, waitlistState.customer?._id, waitlistState.venue])
	// }, [defaultWaitlist, handleCloseModal, waitlistId, waitlistState.customer?._id, waitlistState.venue])

	const handleShowMenu = () => {
		let url = `${process.env.REACT_APP_MENU_URL}/menu/${venueInfo._id}`

		window.open(url, '_blank')
	}

	const handleSocketIoConnection = useCallback(
		(waitlistId: string) => {
			const options = {
				query: {
					waitlistId,
				},
				path: '/waitlistSocket/',
				transports: ['websocket', 'pooling'],
			}

			// Connecting the websocket through the customersApi
			const url = `${process.env.REACT_APP_CUSTOMER_BASE_URL}/waitlist`
			const socket = connectWebsocket(url, options)

			socket.on(
				WAITLIST_EVENT_TYPE.UPDATE_POSITION,
				(content: { position: number; globalPosition: number }) => {
					const { position, globalPosition } = content
					setPosition(position)
					setGlobalPosition(globalPosition)
				}
			)

			socket.on(WAITLIST_EVENT_TYPE.UPDATE_WAITLIST, () => {
				handleGetWaitlistData()
			})

			socket.on(WAITLIST_EVENT_TYPE.CALL_WAITLIST, () => {
				setWaitlistStatus(WaitlistStatusType.NOTIFIED)
			})

			//socket para delayed.
			//socket made to show a notification that appears when the client is late.
			socket.on(WAITLIST_EVENT_TYPE.DELAY_WAITLIST, () => {
				const delayedNotification = {
					title: venueInfo.venueName[currentLanguage.code],
					body: venueInfo.messagesPush.delay?.message[currentLanguage.code],
				}
				showNotification(delayedNotification)
			})
		},
		[currentLanguage.code, handleGetWaitlistData, venueInfo.messagesPush.delay?.message, venueInfo.venueName]
	)

	// --------------------------- USE EFFECTS
	// Get waitlist information from the backend
	useEffect(() => {
		handleGetWaitlistData()
	}, [handleGetWaitlistData, waitlistId])

	// Stablish the connection with the socketIo from the CommunicationsApi
	useEffect(() => {
		handleSocketIoConnection(waitlistId!)
	}, [handleSocketIoConnection, waitlistId])

	// Rehydrates the globalState venueInfo
	useEffect(() => {
		async function updateVenueInfo() {
			try {
				// Fetch the information available in the sessionStorage
				const { sessionStorageFilled, sessionStorageId, parsedSessionStorage } =
					handleSessionStorage('venueInfo')

				// It checks if the waitlistState is properly filled
				const venueId = waitlistState.venue

				// The conditionals bellow only will be checked if the venueInfo (global state) is not filled
				// If the sessionStorage is properly filled, the venueInfo will be set based in this information,
				// avoiding unncessary resquests
				if (sessionStorageFilled && sessionStorageId === venueId) {
					return dispatch(venueInfoUpdated(parsedSessionStorage))
				}

				// If the sessionStorage is not properly filled and the venueId is available,
				// it will fetch the necessary information in the API and update the venueInfo.
				if (venueId) {
					// Thunk Action for fetching data in the API and updating the global state venueInfo
					const successUpdateVenueInfo = (await dispatch(
						fetchVenueInfoData(venueId)
					)) as SuccessUpdateVenueInfo

					if (successUpdateVenueInfo.errorsMessages.length > 0) {
						let hasOnlyThemeError = false

						for (const errorMessage of successUpdateVenueInfo.errorsMessages) {
							hasOnlyThemeError =
								successUpdateVenueInfo.errorsMessages.length === 1 && errorMessage.includes('THEME')

							Sentry.configureScope((scope) => {
								scope.setLevel('warning')
							})
							Sentry.setTag('venueId', waitlistState.venue)
							Sentry.captureMessage(errorMessage)
						}

						if (!hasOnlyThemeError) {
							setModalInfo({
								show: true,
								closable: false,
								type: ModalTypes.noIcon,
								message: 'Waitlist is currently inaccessible.',
								buttons: [
									{
										label: 'OK',
										action: handleToSmartlink,
									},
								],
							})
						}
					}
				}
			} catch (err) {
				setModalInfo({
					show: true,
					onClose: handleCloseModal,
					type: ModalTypes.error,
					message: 'Failed to get costumer information.',
					buttons: [
						{
							label: 'OK',
							action: handleCloseModal,
						},
					],
				})
			}
		}

		// If the venueInfo is filled, we don't need to proceed, so the fetchVenueData function bellow won't be called
		if (!venueInfo._id) {
			updateVenueInfo()
		}
	}, [dispatch, handleCloseModal, handleToSmartlink, venueInfo._id, waitlistState])

	// Update the sessionStorage
	useEffect(() => {
		let sessionVenueInfo = sessionStorage.getItem('venueInfo')

		// If the sessionStore is null and the venueInfoState is not empty, it will update the sessionStorage, otherwise it won't
		if (!sessionVenueInfo && venueInfo._id) {
			sessionStorage.setItem('venueInfo', JSON.stringify(venueInfo))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [venueInfo])

	// --------------------------- USE EFFECTS

	// HTML handles
	const handleQueuePositionPhrase = () => {
		// const { _id: venueId } = venueInfo
		if (waitlistState.operationMode === OperationModeType.ADVANCED) {
			return (
				<p>
					{venueInfo.genericVenue
						? t('Your position in waitlist for ')
						: t('Your position in waitlist for a table for ')}
					<strong>
						{waitlistState.partySize} {waitlistState.partySize === 1 ? t('person') : t('people')}
					</strong>
					<br />
				</p>
			)
		}
	}

	useEffect(() => {
		switch (waitlistStatus) {
			case WaitlistStatusType.SEATED:
				//Show the message when the client seats at the table
				const messagePushSeated = venueInfo.messagesPush.seat
				if (venueInfo.messagesPush.enableMessage && messagePushSeated?.enabled) {
					//prepares the information to be shown to the client
					const seatedNotification = {
						title: venueInfo.venueName[currentLanguage.code],
						body: messagePushSeated.message[currentLanguage.code],
					}
					showNotification(seatedNotification)
				}
				break
			case WaitlistStatusType.CANCELED:
				//Show the message when the client has been canceled.
				const messagePushCanceled = venueInfo.messagesPush.cancel
				if (venueInfo.messagesPush.enableMessage && messagePushCanceled?.enabled) {
					//prepares the information to be shown to the client
					const option = {
						title: venueInfo.venueName[currentLanguage.code],
						body: messagePushCanceled.message[currentLanguage.code],
					}
					showNotification(option)
				}
				break
			case WaitlistStatusType.NOTIFIED:
				//Show the message when the client is called to the table
				const messagePushCalled = venueInfo.messagesPush.call
				if (venueInfo.messagesPush.enableMessage && messagePushCalled?.enabled) {
					//prepares the information to be shown to the client
					const option = {
						title: venueInfo.venueName[currentLanguage.code],
						body: messagePushCalled.message[currentLanguage.code],
					}
					showNotification(option)
				}
				break
		}
	}, [
		currentLanguage.code,
		venueInfo.messagesPush.call,
		venueInfo.messagesPush.cancel,
		venueInfo.messagesPush.enableMessage,
		venueInfo.messagesPush.seat,
		venueInfo.venueName,
		waitlistStatus,
	])

	const handleClientPosition = () => {
		switch (waitlistStatus) {
			case WaitlistStatusType.ACTIVE:
				if (!waitlistState.showClientPosition) {
					return (
						<div id="div_hide_customer_position">
							{t(
								'You are in our online waitlist and soon you will be called. Pay attention to your SMS messages.'
							)}
						</div>
					)
				}

				return (
					<ClientPositionStyles
						colorBackground={venueInfo.color.background || COLORS.background}
						colorMain={venueInfo.color.main || COLORS.main}
					>
						{handleQueuePositionPhrase()}
						<small>{t('Updated in real time')}</small>
						<PositionIndicator position={position || globalPosition} />
						<div>
							<p>
								{t('General Position')}:{' '}
								<span className="highlighted" data-cy="highlight1">
									{globalPosition}º
								</span>
								<br />
								{waitlistState.customerTab ? (
									<>
										{t('Order pad')}:{' '}
										<span className="highlighted" data-cy="highlight2">
											Nº {waitlistState.customerTab}
										</span>
									</>
								) : null}
							</p>
						</div>
					</ClientPositionStyles>
				)
			case WaitlistStatusType.CANCELED:
				return (
					<ClientPositionStyles
						colorBackground={venueInfo.color.background || COLORS.background}
						colorMain={venueInfo.color.main || COLORS.main}
					>
						<div className="div_attention">
							<p>{t('You are no longer on the waitlist.')}</p>
						</div>
					</ClientPositionStyles>
				)
			case WaitlistStatusType.NOTIFIED:
				return (
					<div className="div_call">
						<Player
							autoplay
							loop
							src="https://assets1.lottiefiles.com/packages/lf20_s1ic1mgm.json"
							style={{ height: '207px', width: '207px' }}
						></Player>

						<h1>{t(`It's your turn!`)}</h1>
						<p>{t('We are waiting for you. We hope your experience is perfect!')}</p>
					</div>
				)
			case WaitlistStatusType.SEATED:
				return (
					<div className="div_seat">
						<Player
							autoplay
							loop
							src="https://assets10.lottiefiles.com/packages/lf20_ulzcmgg3.json"
							style={{ height: '207px', width: '207px' }}
						></Player>

						<h1>{t(`Welcome!`)}</h1>
						<p>
							{venueInfo.genericVenue
								? `${t(`It's your turn in and our team is ready to better serve you.`)}`
								: `${t(`It's your turn in our restaurant and our team is ready to better serve you.`)}`}
						</p>
					</div>
				)
		}
	}

	const handleButtonLeaveWaitlist = () => {
		if (waitlistStatus === WaitlistStatusType.ACTIVE) {
			return (
				<div id="div_button_leave_waitlist">
					<button
						className="outline_tagme_button"
						onClick={handleShowModalLeaveWaitlist}
						data-cy="leave_waitlist_button"
					>
						{t('Leave the waitlist')}
					</button>
				</div>
			)
		}
	}

	const handleShowAlertStayClose = () => {
		let customText
		const fallbackText = t('Head to the nearby restaurant, your table should be ready soon.')
		if (waitlistState?.clientNotices?.customerToBeCalled.enabled) {
			customText = waitlistState.clientNotices.customerToBeCalled.message[currentLanguage.code]
		} else {
			customText = fallbackText
		}

		if (position <= positionAlertStayClose && waitlistStatus === WaitlistStatusType.ACTIVE) {
			return (
				<div className="div_attention">
					<p>{customText}</p>
				</div>
			)
		}
	}

	const handleShowPriority = () => {
		let customText
		const fallbackText = t(
			'Some customers are in preferred waitlist. It takes at least half the people to enter.'
		)
		if (waitlistState.priority && waitlistState?.clientNotices?.priorityText.enabled) {
			customText = waitlistState.clientNotices.priorityText.message[currentLanguage.code]
		} else if (waitlistState?.clientNotices?.defaultText.enabled) {
			customText = waitlistState.clientNotices.defaultText.message[currentLanguage.code]
		} else {
			customText = fallbackText
		}

		if (waitlistStatus === WaitlistStatusType.ACTIVE) {
			return (
				<div id="div_priority">
					<p>{customText}</p>
				</div>
			)
		}
	}

	const handleDisclamerServiceTerms = () => {
		if (waitlistStatus === WaitlistStatusType.ACTIVE) {
			return (
				<DisclamerServiceTerms
					colorMain={venueInfo.color.main}
					venueName={venueInfo.venueName[language]}
					minForm={true}
				/>
			)
		}
	}

	const handleHasMenu = () => {
		if (venueInfo.hasMenu) {
			return (
				<FooterStyles
					colorBackground={venueInfo.color.background || COLORS.background}
					colorBorder={venueInfo.color.border || COLORS.divider}
				>
					<div className="divider"></div>

					<p>{t('Have you thought about what to order?')}</p>

					<TagmeButton
						type="button"
						label={t('Take a look at our menu')}
						colorMain={venueInfo.color.main}
						disabled={false}
						handleClick={handleShowMenu}
					/>
				</FooterStyles>
			)
		}
	}

	return (
		<ContainerStyles
			colorBackground={venueInfo.color.background || COLORS.background}
			colorMain={venueInfo.color.main || COLORS.main}
			colorBorder={venueInfo.color.border || COLORS.border}
		>
			<WaitlistHeader />

			{handleClientPosition()}

			{handleButtonLeaveWaitlist()}

			{handleShowAlertStayClose()}

			{handleShowPriority()}

			{handleDisclamerServiceTerms()}

			{handleHasMenu()}

			{handleTagmeModal(modalInfo)}

			<GlobalStyle colorBackground={venueInfo.color.background || '#f5f2ea'} modalOpened={modalInfo.show} />
		</ContainerStyles>
	)
}

export default WaitList
