import { COLORS } from '../../common/constants'
import { TagmeButtonProps } from './interfaces'
import { TagmeButtonStyles } from './styles'

function TagmeButton(props: TagmeButtonProps) {
	const { label, type, colorMain, disabled, handleClick } = props

	return (
		<TagmeButtonStyles colorMain={colorMain || COLORS.main}>
			<button type={type} onClick={handleClick} className={disabled ? 'disabled' : ''} data-cy="tagme_button">
				{label}
			</button>
		</TagmeButtonStyles>
	)
}

export default TagmeButton
