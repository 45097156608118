export enum ErrorsList {
	INVALID_VENUEID_FORMAT = 'INVALID_VENUEID_FORMAT',
	INVALID_WAITLISTID_FORMAT = 'INVALID_WAITLISTID_FORMAT',
	FAILED_TO_GET_WAITLIST_INFORMATION = 'FAILED_TO_GET_WAITLIST_INFORMATION',
	FAILED_TO_GET_WAITLIST_INFORMATION_WITH_POSITION = 'FAILED_TO_GET_WAITLIST_INFORMATION_WITH_POSITION',
	FAILED_TO_GET_WAITLIST_STATUS = 'FAILED_TO_GET_WAITLIST_STATUS',
	FAILED_TO_LEAVE_WAITLIST = 'FAILED_TO_LEAVE_WAITLIST',
	INVALID_VENUE = 'ERROR_-_INVALID_VENUE',
	WAITLIST_STATUS_NOT_ACTIVE = 'WAITLIST_STATUS_NOT_ACTIVE',
	VENUE_INFO_NOT_FOUND = 'VENUE_INFO_NOT_FOUND',
	THEME_NOT_FOUND = 'THEME_NOT_FOUND',
	WAITLIST_SETTINGS_NOT_FOUND = 'WAITLIST_SETTINGS_NOT_FOUND',
	FAILED_TO_CREATE_NEW_WAITLIST = 'FAILED_TO_CREATE_NEW_WAITLIST',
	FAILED_TO_GET_VENUE_INFO_FROM_CUSTOMER_API = 'FAILED_TO_GET_VENUE_INFO_FROM_CUSTOMER_API',
	FAILED_TO_GET_THEME_FROM_CUSTOMER_API = 'FAILED_TO_GET_THEME_FROM_CUSTOMER_API',
	FAILED_TO_GET_WAITLIST_SETTINGS_UNAUTHENTICATED_FROM_LEGACY_API = 'FAILED_TO_GET_WAITLIST_SETTINGS_UNAUTHENTICATED_FROM_LEGACY_API',
	UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}
