export interface ModalProps {
	show: boolean
	onClose?: () => void
	closable?: boolean
	hideCloseButton?: boolean
	closeOutsideClick?: boolean
	children?: React.ReactNode
	title?: string
	message?: string | string[]
	type: ModalTypes
	buttons?: ButtonsProps[] | null
}

export enum ModalTypes {
	error = 'error',
	noIcon = 'noIcon',
	closed = 'closed',
	success = 'success',
	warning = 'warning',
}
export interface ButtonsProps {
	id?: string | null
	label: string
	action: () => any
	type?: ButtonTypes
}
export enum ButtonTypes {
	primary = 'primary',
	error = 'error',
}
