import styled from 'styled-components'

export const Container = styled('div')<{
	colorBackground: string
}>`
	padding: 0 10px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: ${props => props.colorBackground};

	p {
		margin: 0;
	}

	@media (min-width: 1024px) {
		margin-left: 20%;
		margin-right: 20%;
		//width: max-content;
	}

	.div_icon {
		display: flex;
		color: #ff813c;
		font-size: 80px;
		margin-top: 20px;
		justify-content: center;
	}

	h3 {
		font-size: 20px;
	}

	.topic {
		font-size: 16px;
		margin: 5px auto;
		font-weight: bold;
	}

	.text_style {
		color: #30323b;
		font-size: 14px;
		line-height: 18px;
	}

	.sub_title {
		font-weight: bold;
		font-size: 15px;
	}

	#agreement {
		color: green;
		padding-bottom: 15px;
	}

	.border {
		padding: 10px 0;
		border-top: 1px solid #eaeaea;
	}

	#powered_by_tagme {
		color: #a1a1a6;
		margin-bottom: 10px;
	}

	@media (min-width: 386px) {
		width: fit-content;
	}

	.powerTagmeIcon {
		font-size: 25px;
	}

	h4 {
		margin: 0;
		font-size: 12px;
		font-weight: 300;
	}

	.weekDayStyle {
		font-size: 12px;
	}
`
