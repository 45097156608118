// Styles
import { ModalStyles } from './styles'

//Interface
import { ModalProps } from './interface'

// Images
import successImage from '../../assets/images/confirmed.png'
import closedImage from '../../assets/images/ilustra-fechado.svg'
import alertImage from '../../assets/images/ilustra-sino-alerta.svg'
import disconnectedImage from '../../assets/images/ilustra-desconectado.svg'

// Languages
import { useTranslation } from 'react-i18next'

//React
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducer'
import { COLORS } from '../../common/constants'

function TagmeModal(props: ModalProps) {
	const {
		show,
		onClose,
		closable,
		hideCloseButton,
		closeOutsideClick,
		title,
		message,
		children,
		buttons,
		type,
	} = props

	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	// Handle the text translation
	const { t } = useTranslation()

	if (!show) {
		return null
	}

	const changeIcon = () => {
		let src = disconnectedImage

		switch (type) {
			case 'error':
				src = disconnectedImage
				break

			case 'closed':
				src = closedImage
				break

			case 'success':
				src = successImage
				break

			case 'warning':
				src = alertImage
				break
		}

		return src
	}

	const generateMessage = () => {
		if (typeof message === 'object') {
			const listMessages: JSX.Element[] = []

			message.forEach((item, index) => {
				listMessages.push(
					<div id={`message_${index}`} key={`message_${index}`}>
						<p
							id={`paragraph_message${index}`}
							key={`paragraph_message${index}`}
							className="paragraph_message"
						>
							{t(item)}
						</p>
						<br />
					</div>
				)
			})

			return listMessages
		}
	}

	const generateButtons = () => {
		if (!buttons) return <></>

		const listButtons = []

		for (const button of buttons) {
			listButtons.push(
				<button
					id={button.id || `btn_${button.label}`}
					key={button.id || `btn_${button.label}`}
					onClick={button.action}
					className={button.type || ''}
				>
					{t(button.label)}
				</button>
			)
		}

		return listButtons
	}

	return (
		<ModalStyles
			colorMain={venueInfo.color.main || COLORS.main}
			colorBackground={venueInfo.color.background || COLORS.background}
		>
			<div
				className="modal_background"
				onClick={closable === false || closeOutsideClick === false ? undefined : onClose}
			>
				<div
					className="modal_container"
					onClick={closable === false || closeOutsideClick === false ? undefined : e => e.stopPropagation()}
				>
					{closable === false ? (
						<></>
					) : (
						<div className="title_close_button">
							<button onClick={onClose}>
								<i className="icon tagmeicon tagmeicon-close"></i>
							</button>
						</div>
					)}

					<div className="modal_content" data-cy={`modal_content_${type}`}>
						{children ? (
							<div className="modal_body">{children}</div>
						) : (
							<>
								{type !== 'noIcon' ? (
									<img
										className="tagmeicon tagmeicon-importante div_icon"
										src={changeIcon()}
										alt="icon"
										data-cy={`icon_type_${type}`}
									/>
								) : (
									<></>
								)}

								{title ? <h1 className="modal_title">{t(title)}</h1> : <></>}

								{message ? (
									<div className="modal_message">
										{typeof message === 'string' ? t(message) : generateMessage()}
									</div>
								) : (
									<></>
								)}
							</>
						)}

						<div className="modal_footer">
							{closable === false || hideCloseButton ? (
								<></>
							) : (
								<button onClick={onClose} data-cy="close_button">
									{t('Back')}
								</button>
							)}
							{generateButtons()}
						</div>
					</div>
				</div>
			</div>
		</ModalStyles>
	)
}

export default TagmeModal
