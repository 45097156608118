import styled from 'styled-components'

export const ModalStyles = styled('div')<{
	colorMain: string
	colorBackground: string
}>`
	.modal_background {
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);

		display: flex;
		align-items: center;
		justify-content: center;

		top: 0;
		left: 0;
		z-index: 1;
		position: fixed;
	}

	@media (min-width: 768px) {
		.modal_background {
			display: flex;
			padding: initial;
			align-items: center;
			justify-content: center;
		}

		.modal_container {
			max-width: 500px;
			max-height: 500px;
		}
	}

	.modal_container {
		max-height: 85%;
		position: relative;
		border-radius: 2px;
		margin: 0 15px 15px;
		background-color: ${props => props.colorBackground};
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

		.title_close_button {
			right: 0;
			display: flex;
			position: absolute;
			bottom: calc(100% + 5px);
			justify-content: flex-end;

			.icon {
				color: #fff;
				font-size: 40px;
				font-weight: bold;
			}

			button {
				border: none;
				font-size: 25px;
				cursor: pointer;
				background-color: transparent;
			}
		}

		.modal_content {
			width: 98%;
			min-width: 300px;
			display: flex;
			padding: 35px 25px;
			overflow-y: auto;
			max-height: 500px;
			flex-direction: column;
			box-sizing: border-box;

			.div_icon {
				width: 200px;
				height: 200px;
				margin: 0 auto;
				/* font-size: 70px; */
			}

			.modal_title {
				color: #30323b;
				font-size: 16px;
				font-weight: 600;
				line-height: 22px;
				text-align: center;
			}

			.modal_message {
				color: #30323b;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				margin-bottom: 12px;
			}

			.paragraph_message {
				margin: 5px auto;
			}

			@media (max-width: 386px) {
				width: 300px;
			}
		}

		.modal_footer {
			//flex: 20%;
			display: flex;
			align-items: center;
			justify-content: center;

			button {
				width: 150px;
				height: 45px;
				margin: 10px 0 10px 10px;

				padding: 2px;
				color: ${props => props.colorMain};
				min-width: 78px;
				font-size: 12px;
				border-radius: 3px;
				text-align: center;
				text-decoration: none;
				display: inline-block;
				background-color: transparent;
				border: 2px solid ${props => props.colorMain};

				cursor: pointer;
			}

			.primary {
				background-color: ${props => props.colorMain};
				border: 2px solid ${props => props.colorMain};
				color: #fff;
			}

			.error {
				background-color: #de1d48;
				border: 2px solid #de1d48;
				color: #fff;
			}
		}
	}
`
