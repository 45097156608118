import styled from 'styled-components'

export const TranslatorStyles = styled('div')<{
	minForm?: boolean | null
	colorMain: string
	borderColor: string
}>`
	.language_option_container {
		width: 100%;
		height: 50px;
		margin-bottom: 10px;
		background-color: blue;
	}

	.language_list {
		z-index: 1;
		height: 30px;
		cursor: pointer;
		list-style: none;
		padding-left: 3px;
		position: relative;
		border-radius: 3px;
		display: -webkit-box;
		border: 1px solid ${props => props.borderColor};
		//box-shadow: 1px 1px 2px #222;
		width: ${props => (props.minForm ? '50px' : '80px')};

		#div_selected_language {
			display: flex;
			align-items: center;

			img {
				margin: 0 3px;
				//box-shadow: 1px 1px 2px #222;
			}

			.selected_language {
				color: #fff;
				margin: 0 3px;
				font-size: 10px;
				//text-shadow: 1px 1px 2px #222;
			}

			.select-arrow {
				font-weight: bold;
				transform: rotate(90deg);
				//text-shadow: 1px -1px 2px #222;
				color: ${props => (props.minForm ? props.colorMain : '#fff')};
			}

			.rotate-select-arrow {
				transform: rotate(270deg);
			}
		}
	}

	.language_select {
		.option_language {
			display: flex;
			padding: 15px;
			font-size: 14px;
			min-width: 255px;
			margin-bottom: 10px;
			align-items: center;
			border: 1px solid ${props => props.borderColor};
			justify-content: space-between;

			@media (max-width: 375px) {
				min-width: auto;
			}

			.display_alignment {
				display: flex;
				align-items: center;
				justify-content: space-around;

				.option_language_name {
					color: #4a4a4a;
					margin-left: 10px;
				}
			}

			#div_toogle_select {
				.toogle_select {
					height: 16px;
				}
			}
		}
		.last_option {
			margin-bottom: 0;
		}
	}
`
