import axios from 'axios'

// * CUSTOMER API
// Config this axios intance to reiceive the base URL bellow
const customerApi = axios.create({
	baseURL: process.env.REACT_APP_CUSTOMER_BASE_URL,
})
// * CUSTOMER API

export { customerApi }
