// Link Pages
import { Link } from 'react-router-dom'

// Translation
import { useTranslation } from 'react-i18next'

// Style Component
import { DisclamerServiceTermsStyles } from './styles'
import { DisclamerServiceTermsProps } from './interfaces'
import { COLORS } from '../../common/constants'

function DisclamerServiceTerms(props: DisclamerServiceTermsProps) {
	const { t } = useTranslation()

	const { minForm, venueName, colorMain } = props

	const generateLabel = () => {
		let label = (
			<p>
				{t('On continuing you accept the')}{' '}
				<Link to="/terms-service" className="link-style" data-cy="terms_of_service">
					{t('Terms of Service and Online waitlist')}
				</Link>{' '}
				{t(
					'in venueName with Tagme and accept sharing your informations with venueName to peform the requested transaction',
					{ venueName }
				)}
			</p>
		)

		if (minForm) {
			label = (
				<p className="dynamic-align">
					{t('On continuing you accept the')}{' '}
					<Link to="/terms-service" className="link-style">
						{t('Terms of Service and Online waitlist')}
					</Link>
				</p>
			)
		}

		return label
	}

	return (
		<DisclamerServiceTermsStyles colorMain={colorMain || COLORS.main}>
			<div className="disclamer">{generateLabel()}</div>
		</DisclamerServiceTermsStyles>
	)
}

export default DisclamerServiceTerms
