import styled from 'styled-components'

export const TagmeButtonStyles = styled('div')<{
	colorMain: string
}>`
	button {
		border: 0;
		padding: 0;
		width: 100%;
		color: white;
		cursor: pointer;
		font-size: 14px;
		margin-top: 10px;
		line-height: 46px;
		border-radius: 4px;
		background-color: ${props => props.colorMain};
	}

	.disabled {
		opacity: 60%;
	}
`
