import InputMask from 'react-input-mask'
import { useSelector } from 'react-redux'
import { COLORS } from '../../common/constants'
import { RootState } from '../../store/reducer'

import { TagmeInputProps } from './interfaces'
import { TagmeInputStyles } from './styles'

function TagmeInput(props: TagmeInputProps) {
	const {
		border,
		icon,
		label,
		placeholder,
		name,
		type,
		mask,
		valid,
		hint,
		featuredEnabled,
		hasError,
		errorMsg,
		maxLength,
		state,
		setState,
	} = props

	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	return (
		<TagmeInputStyles
			colorAccentBackground={venueInfo.color.accentBackground || COLORS.accentBackground}
			colorBorder={venueInfo.color.border || COLORS.border}
		>
			<div
				className={`container${border ? '' : ' no_border'}${featuredEnabled && valid ? ' valid' : ''} ${
					hasError ? ' has_error' : ''
				}`}
				data-cy={`${name}_input_div`}
			>
				<div className="icon_container">
					<label htmlFor={name} data-cy={`${name}_label`}>
						<i className={`icon tagmeicon ${icon}`}></i>
						{featuredEnabled && valid ? <i className="valid tagmeicon tagmeicon-ok"></i> : ''}
					</label>
				</div>

				<div className="input_container">
					<label className="label" htmlFor={name}>
						{label}
					</label>

					{mask ? (
						<InputMask
							mask={mask}
							className={`input${featuredEnabled && valid ? ' valid' : ''}`}
							type={type}
							placeholder={placeholder}
							value={state || ''}
							onChange={e => setState(e.target.value)}
							autoComplete="off"
							id={name}
						></InputMask>
					) : (
						<input
							className={`input${featuredEnabled && valid ? ' valid' : ''}`}
							type={type}
							placeholder={placeholder}
							id={name}
							value={state || ''}
							onChange={e => setState(e.target.value)}
							maxLength={maxLength}
							autoComplete="off"
						/>
					)}
				</div>
				{hint && !state ? (
					<div className="hint" data-cy="hint">
						<div className="container">{hint}</div>
					</div>
				) : (
					''
				)}
				{state && hasError && errorMsg ? (
					<div className="hint" data-cy="hint_error">
						<div className="container">{errorMsg}</div>
					</div>
				) : (
					''
				)}
			</div>
		</TagmeInputStyles>
	)
}

export default TagmeInput
