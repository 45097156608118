// Interfaces
import { PositionIndicatorProps } from './interfaces'

// Styles
import { PositionStyles } from './styles'

function PositionIndicator(props: PositionIndicatorProps) {
	const { position } = props

	return (
		<PositionStyles>
			<div id="div_position">
				<div id="position" data-cy="position">
					{position || '-'}º
				</div>
			</div>
			<svg viewBox="25 25 50 50">
				<circle cx="50" cy="50" r="20"></circle>
			</svg>
		</PositionStyles>
	)
}

export default PositionIndicator
