// History
import { useNavigate } from 'react-router-dom'

// Languages
import { useTranslation } from 'react-i18next'

// Styles
import { TagmeButtonBackStyles } from './styles'

function TagmeButtonBack() {
	// Handle the text translation
	const { t } = useTranslation()

	let history = useNavigate()

	const handleBack = () => {
		history(-1)
	}

	return (
		<TagmeButtonBackStyles>
			<button onClick={handleBack}>
				<i className={`icon tagmeicon tagmeicon-voltar`}></i>
				<span id="label_back_button">{t('Return')}</span>
				<div className="aligner"></div>
			</button>
		</TagmeButtonBackStyles>
	)
}

export default TagmeButtonBack
