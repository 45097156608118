import styled from 'styled-components'

export const DisclamerServiceTermsStyles = styled('div')<{
	colorMain: string
}>`
	.disclamer {
		color: #30323b;
		font-size: 12px;
		font-weight: 300;
		margin-top: 10px;
		line-height: 14px;
		padding-bottom: 105px;
	}

	.dynamic-align {
		@media (min-width: 386px) {
			text-align: center;
		}
	}

	p {
		color: #30323b;

		.link-style {
			color: ${props => props.colorMain};
		}
	}
`
