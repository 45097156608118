import styled from 'styled-components'

export const TagmeInputStyles = styled.div<{ colorAccentBackground: string; colorBorder: string }>`
	.container {
		display: flex;
		padding: 11px 0;
		position: relative;
		border-bottom: 1px solid ${props => props.colorBorder};
	}

	.valid {
		background: ${p => p.colorAccentBackground};
	}

	.no_border {
		border: none;
	}

	.has_error {
		color: #de1d48;
	}

	.icon_container {
		width: 50px;
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;

		.icon {
			font-size: 32px;
		}

		.valid {
			top: 4px;
			right: 8px;
			width: 14px;
			height: 14px;
			display: flex;
			color: #f1f1f1;
			margin-right: 2px;
			border-radius: 50%;
			position: absolute;
			align-items: center;
			justify-content: center;
			background-color: #71c879;
		}
	}

	.input_container {
		flex: 1;
		display: flex;
		position: relative;
		flex-direction: column;

		.label {
			margin: 0;
			color: #4a4a4a;
			cursor: pointer;
			font-size: 14px;
			line-height: 16px;
			font-weight: normal;
		}

		.input {
			border: 0;
			margin: 0;
			color: #212529;
			padding: 1px 0;
			font-size: 14px;
			background-color: transparent;

			&::placeholder {
				opacity: 0.6;
				color: #464646;
			}

			&:focus,
			&:hover,
			&:visited,
			&:active {
				outline: 0;
				box-shadow: none;
				-webkit-appearance: none;
			}
		}
	}

	.hint {
		display: flex;
		align-items: center;
		justify-content: center;

		.container {
			color: #eaeaea;
			font-size: 12px;
			font-weight: 300;
			padding: 1px 4px;
			position: relative;
			border-radius: 3px;
			background: #9b9b9b;
			align-items: center;
			display: inline-flex;
			justify-content: center;

			&::before {
				left: 0;
				top: 50%;
				width: 7px;
				height: 7px;
				content: '';
				position: absolute;
				background: #9b9b9b;
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}
	}

	.has_error .hint {
		.container {
			background: #de1d48;

			&::before {
				background: #de1d48;
			}
		}
	}

	.has_error .input_container {
		.label {
			color: #de1d48;
		}
	}
`
