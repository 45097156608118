// Styles
import { Container } from './styles'
import { GlobalStyle } from '../../styles/global'

// Images
import padlock from '../../assets/images/cadeado.svg'
import tagmePoweredBy from '../../assets/images/tagme/tagme-powered-by.svg'

// Languages
import { useTranslation } from 'react-i18next'

// Components
import TagmeButtonBack from '../../Components/TagmeButtonBack'

// Redux
import { RootState } from '../../store/reducer'
import { useSelector } from 'react-redux'

function TermsOfService() {
	// Handle the text translation
	const { t } = useTranslation()
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	// ! THIS INFO WILL COME FROM BACKEND
	// const schedule = [
	// 	{
	// 		day: 0, //Sunday
	// 		start: '16:00',
	// 		end: '23:59',
	// 	},
	// 	{
	// 		day: 1,
	// 		start: '10:00',
	// 		end: '17:00',
	// 	},
	// 	{
	// 		day: 2,
	// 		start: '10:00',
	// 		end: '17:00',
	// 	},
	// 	{
	// 		day: 3,
	// 		start: '10:00',
	// 		end: '17:00',
	// 	},
	// 	{
	// 		day: 4,
	// 		start: '10:00',
	// 		end: '17:00',
	// 	},
	// 	{
	// 		day: 5,
	// 		start: '10:00',
	// 		end: '17:00',
	// 	},
	// 	{
	// 		day: 6,
	// 		start: '16:00',
	// 		end: '23:59',
	// 	},
	// ]

	// const generateScheduleInfo = () => {
	// 	const scheduleInfo = []

	// 	for (const item of schedule) {
	//		! CHANGE FROM MAP FUNCTION TO DAYJS
	// 		const weekDay = mapWeekdaysNames(item.day)

	// 		scheduleInfo.push(
	// 			<div className="weekDayStyle">
	// 				{weekDay}
	// 				{item.start} às {item.end}
	// 			</div>
	// 		)
	// 	}

	// 	return scheduleInfo
	// }

	// const mapWeekdaysNames = (scheduleDay: Number) => {
	// 	let weekDay = ""

	// 	switch (scheduleDay) {
	// 		case 0:
	// 			weekDay = 'Domingo'
	// 			break

	// 		case 1:
	// 			weekDay = 'Segunda-feira'
	// 			break

	// 		case 2:
	// 			weekDay = 'Terça-feira'
	// 			break

	// 		case 3:
	// 			weekDay = 'Quarta-feira'
	// 			break

	// 		case 4:
	// 			weekDay = 'Quinta-feira'
	// 			break

	// 		case 5:
	// 			weekDay = 'Sexta-feira'
	// 			break

	// 		case 6:
	// 			weekDay = 'Sábado'
	// 			break
	// 	}

	// 	return weekDay
	// }

	return (
		<Container colorBackground={venueInfo.color.background || '#f5f2ea'}>
			<TagmeButtonBack />

			<img className="tagmeicon tagmeicon-importante div_icon" src={padlock} alt="Cadeado" />

			<h3>{t('Terms of Service and waitlist.')}</h3>

			<div id="div_general">
				<p className="topic">{t('General information')}</p>

				<br />

				<div className="text_style">
					<p className="sub_title">
						{t('Welcome to the online Waiting List! Please pay attention to the following information:')}
					</p>

					<br />

					<p>
						{t(
							'The Waiting List (online or in person) allows you to monitor your position in real time, through a link sent by SMS to your cell phone number or equipment provided by the restaurant. There is no additional charge.'
						)}
					</p>

					<br />

					<p>
						{t(
							'Wait tolerance may vary depending on the size of the list. Please be close to the service desk when there are few people in front of you.'
						)}
					</p>

					<br />

					<p>
						{t(
							'Table selection is related to the number of people informed by you. The restaurant does not guarantee a table when the number of people exceeds that informed. It is necessary that most people are present to enter the restaurant, pay attention to the rules of each restaurant.'
						)}
					</p>

					<br />

					<p>
						{t(
							'The restaurant will send an SMS to your registered phone when it is your turn. When called, look for the receptionist to be allocated to your desk.'
						)}
					</p>

					<br />

					<p>
						{t(
							'Please check directly with the restaurant for specific observations and table reservation conditions.'
						)}
					</p>
				</div>
			</div>

			{/* 
      <div id="div_schedule">
        <p className="topic">Horários</p>
        <div className="div_schedule_info">{generateScheduleInfo()}</div>
      </div> */}

			<br />

			<div id="div_security">
				<p className="topic">{t('Security Information and Acceptance')}</p>

				<div className="text_style">
					<p>
						{t(
							"By using the Waiting List (online or in person) or Table Reservation services, you acknowledge that no information system is inviolable. However, Tagme makes the best efforts to maintain the security and confidentiality of your personal data, through the adoption of security, technical and administrative measures, sufficient to protect them from unauthorized access and from accidental or illegal situations of destruction, loss, alteration, communication or any form of improper or unlawful treatment. These cautions extend to Tagme's partners and suppliers."
						)}
					</p>

					<br />

					<p>
						{t(
							'You can contact Tagme through the e-mail lgpd@tagme.com.br in case of doubts and/or to exercise any of your rights, including, but not limited to (i) confirmation of treatment of your personal data, (ii) access, correction, portability and deletion of personal data and (iii) information about sharing personal data with third parties. Tagme will respond to your request within a reasonable period of time, in a transparent and reasoned manner.'
						)}
					</p>

					<br />

					<p>
						{t(
							'The use of the Waiting List service (online or in person) or Table Reservation implies acceptance and agreement regarding the collection, treatment and sharing of your personal data by Tagme, including, but not limited to (i) name, (ii) ) mobile phone, (iii) navigation and geolocation data and (iv) restaurant consumption data (when applicable).'
						)}
					</p>

					<br />

					<p>
						{t(
							'Personal data is collected and processed in accordance with the General Law for the Protection of Personal Data (“LGPD”) and may be used for the following purposes: (i) to create and manage the registration of an account and user profile; (ii) manage the reservation and waiting list; (iii) manage orders placed with the restaurant (when applicable); (iv) manage the loyalty programs of Tagme, restaurants and its partners; (v) allow the development and improvement of products and services by Tagme, restaurants and its partners; (vi) personalization of your experience as a customer, including self-completion of registrations, offer of benefits and marketing communication from Tagme, restaurants and its partners; (vii) sending an invitation to participate in studies and research; (viii) responding to requests from competent authorities and (IV) carrying out the business activities of Tagme, restaurants and its partners.'
						)}
					</p>

					<br />

					<p>
						{t(
							'Personal data will be stored for the period necessary to fulfill the aforementioned purposes and may be deleted from the database at your request, provided that such deletion is not prohibited by applicable legislation.'
						)}
					</p>

					<br />

					<p>
						{t(
							'Tagme is not responsible for the misuse of information collected directly by the restaurant or third parties.'
						)}
					</p>

					<br />

					<p id="agreement">
						{t(
							'By continuing, you accept the Terms of Service at this merchant with Tagme, and specifically accept that Tagme share your information with this merchant to complete the requested transaction.'
						)}
					</p>
				</div>
			</div>

			<div className="border"></div>

			<div id="powered_by_tagme">
				<img src={tagmePoweredBy} alt="Powered by Tagme" />
			</div>

			<GlobalStyle />
		</Container>
	)
}

export default TermsOfService
