import styled from 'styled-components'

export const NotFoundPageStyles = styled.header`
	display: flex;
	min-height: 100vh;
	text-align: center;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	font-size: calc(10px + 2vmin);

	img {
		max-height: 300px;
	}
`
