import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Languages } from '../Components/LanguageSelector/interfaces'
import * as flag from '../assets/images/languages'
import { AppDispatch, AppThunk } from './configureStore'

// CONFIGURATION IN SRC/INDEX.TSX
export const languagesList: Languages[] = [
	{
		code: 'pt',
		name: 'PT-BR',
		fullName: 'Português do Brasil',
		country_code: 'br',
		flag: flag.flagPtBr,
	},
	{
		code: 'en',
		name: 'EN-US',
		fullName: 'American English',
		country_code: 'us',
		flag: flag.flagEnUs,
	},
]

const initialState = (): Languages => {
	const storageLanguageCode = localStorage.getItem('i18nextLng')
	const storageLanguage = languagesList.find(l => l.code === storageLanguageCode) || languagesList[0]
	return storageLanguage
}

export const languageSlice = createSlice({
	name: 'language',
	initialState,
	reducers: {
		changeLanguage(_, action: PayloadAction<Languages>) {
			return action.payload
		},
	},
})

const { actions, reducer } = languageSlice

export const { changeLanguage } = actions

export default reducer

// Thunk for set localStorage
export function changeLanguageAndSetLocalStorage(language: Languages): AppThunk | any {
	return (dispatch: AppDispatch) => {
		localStorage.setItem('i18nextLng', language.code)
		dispatch(changeLanguage(language))
	}
}
