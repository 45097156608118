//var notification: Notification

//fallback of the notifiation
var options = {
	title: 'Olá! esse é uma notificação', // mudar para o nome do restaurante
	body: 'Este é o corpo da notificação',
	//icon: './assets/images/tagme/tagme.svg',
}

//function responsable to create what will be shown.
function createNotification(option: any): Notification {
	options.body = option.body
	return new Notification(option.title, options)
}

//function responsible to verify if the notifications are enabled in the browser.
export function showNotification(option: any) {
	//verification of desktop support of the tecnology.
	if (!('Notification' in window)) {
		alert('This browser does not support desktop notification')
	}

	//verify if the permission for notifications are granted by the user.
	else if (Notification.permission === 'granted') {
		createNotification(option)
	} else if (Notification.permission !== 'denied') {
		//will ask for permission to the client to deliver the information.
		Notification.requestPermission().then(function (permission) {
			if (permission === 'granted') {
				createNotification(option)
			}
		})
	}

	// Plus!
	// notification.onclose = function () {
	// 	console.log('Notification closed')
	// }
}
