import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { customerApi } from "../../api/axios.config"


function RemoveClient() {

    const { waitlistId } = useParams()

    const history = useNavigate()

    useEffect(() => {
        const removeCustomer = async () => {
            try {
                await customerApi.put(`/waitlist/${waitlistId}/cancel`, { cancelReason: 'Customer' })
            } catch (err) {
                console.error(err)
            }


            history(`/waitlist/${waitlistId}`)

        }

        removeCustomer()


    }, [history, waitlistId])

    return (<></>)
}

export default RemoveClient